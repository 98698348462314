import Vue from 'vue'
import ArtMessage, { typeIcoinTextMap } from '../components/common/artMessage'

const ArtMessageClass = Vue.extend(ArtMessage)

/**
 * 调用消息组件，兼容 element-ui vm.$message
 * @param data
 * @param options
 * @param config
 */
function message (data = {message: 'success', type: 'success'}, options = {data}, config) {
  // 若为字符串，作为类型处理
  if (typeof data === 'string') {
    let type = 'info'
    if (typeIcoinTextMap[data]) {
      type = data
    } else {
      console.warn(`${data} 类型设置不正确！默认为 info 类型`)
    }
    data = {
      type
    }
    // options 若为字符串，作为 message 处理
    if (typeof options === 'string') {
      data.message = options
      options = config || {}
      // options 若为对象，作为 options 处理不变，type 和 message 等同
    } else if (options) {
      data.message = data.type
      // options 若为其他类型，强制转为字符串作为 message 处理
    } else {
      data.message = String(options)
      options = config || {}
    }
  }
  if (options.data) {
    Object.assign(options.data, data)
  } else {
    options.data = data
  }

  let artMessage = new ArtMessageClass({
    el: document.createElement('div'),
    store: this.$store,
    router: this.$router
  })
  Vue.nextTick(() => {
    document.body.appendChild(artMessage.$el)
    Object.assign(artMessage, options.data)
  })
}

let $message
Object.defineProperty(Vue.prototype, '$message', {
  get () {
    if (!$message) {
      $message = message.bind(this)
      Object.keys(typeIcoinTextMap).forEach(type => {
        $message[type] = function (...args) {
          $message(type, ...args)
        }
      })
    }
    return $message
  }
})
