import Vue from 'vue'
import VueRouter from 'vue-router'

if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
    Vue.prototype.$mobile = true
}

let routes = []
if (Vue.prototype.$mobile) {
    routes = [
        {
            path: '/',
            exact: true,
            name: 'Index',
            component: () => import(/* webpackChunkName: "home" */ '../pages/m/index.vue')
        },
        {
            path: '/register',
            name: 'Index',
            component: () => import(/* webpackChunkName: "register" */ '../pages/m/register')
        }
    ]
} else {
    routes = [
        {
            path: '/',
            exact: true,
            name: 'Index',
            component: () => import(/* webpackChunkName: "home" */ '../pages/pc/index.vue')
        },
        {
            path: '/register',
            name: 'register',
            component: () => import(/* webpackChunkName: "register" */ '../pages/pc/register')
        }
    ]
}
console.log(Vue.prototype.$mobile, routes)
Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
