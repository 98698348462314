import Vue from 'vue'
import axios from 'axios'
import { local } from '../config/constant'

let axiosInstance

export const $axios = function(options, config) {
    let { method, data, timeout } = options
    Object.assign(options, {
        headers: {},
        withCredentials: false,
        timeout: timeout || 1000 * 60 * 5
    })
    options.headers = {
        'Accept': 'application/json, text/javascript',
        'Content-Type': 'application/json;charset=UTF-8'
    }

    document.cookie= `locale=${localStorage.getItem(local.LOCALE) || 'en-US'}`
    if (config && config.headers) {
        Object.assign(options.headers, config.headers)
    }
    if (method !== 'get') {
        options.data = data
    } else {
        options.params = data
    }
    // throw new Error(`未知的HTTP Method:${method}`)
    if (!axiosInstance) {
        axiosInstance = axios.create({
            withCredentials: true
        })
    }
    axiosInstance.interceptors.response.use(
        (response)=>{
            return response;
        },
        (error) => {
            console.log(error)
            throw new Error(error)
    })
    axiosInstance.interceptors.request.use((data) => {
            // console.log(data)
            return data
        },
        (error) => {
            throw new Error(error)
    })
    return axiosInstance(options).then(res => {
        return res.data
    })
}

const context = {
    $axios
}

export const $ajax = function(namespace, options = {}, config = {}) {
    const _this = this || context
    if (!namespace || typeof namespace !== 'string') {
        config = options
        options = namespace
    }
    let apiOptions = options
    if (!_this['_promises']) {
        _this['_promises'] = {}
    }
    let abort = () => {}
    if (namespace) {
        let CancelToken = axios.CancelToken
        apiOptions.cancelToken = new CancelToken(function executor (cancel) {
            abort = cancel
        })
        _this['_promises'][namespace] = abort
    }
    return _this.$axios(apiOptions, config)
}

export const $abort = function() {
    const _this = this || context
    for (let key in _this['_promises']) {
        _this['_promises'][key]('取消请求了')
    }
}

Object.assign(Vue.prototype, context, {
    $ajax,
    $abort
})

Vue.mixin({
    destroyed () {
        this.$abort()
        delete this['_promises']
    }
})
