import VueI18n from 'vue-i18n'
import Vue from 'vue'
import { local } from '../constant'
import cn from './zh'
import en from './en'

Vue.use(VueI18n)

export default new VueI18n({
    locale: localStorage.getItem(local.LOCALE) || 'en-US',
    messages: {
        'zh-CN': cn,
        'en-US': en
    }
})
