export default {
    local: '中文',
    header1: 'What is BLOWUP',
    header2: 'Tokenomics',
    header3: 'How to Buy',
    header4: 'Roadmap',
    content1_1: 'A decentralized hedge fund to insure traders from the impact of crypto market volatility',
    content1_2: 'Buy Now',
    content2_1: 'BLOWUP  FACTS',
    content2_2: 'Liquidity',
    content2_3: 'Holders',
    content2_4: 'Supply',
    content2_5: 'Market Cap',
    content2_6: 'Live Chart',
    content3_1: '&nbsp;&nbsp;We believe that “always have a backup plan”, there is no guarantee that crypto traders will always close their trade in profit.  While we cannot protect our interests against all risks, we created BLOWUP that can offer financial reimbursement against losses in the crypto world.\n' +
        '            <br /><br />\n' +
        '            With that thought bright in our minds, we began developing the BLOWUP as a plan B for crypto traders.\n' +
        '            <br /><br />\n' +
        '            BLOWUP is a decentralized token developed on the Binance Smart Chain, we aim to establish a new consensus mechanism by adopting the Oracles & Smart Contract.\n' +
        '            <br /><br />\n' +
        '            The market BTC price data is input to the blockchain through Oracle. Within 24 hours(GMT), if the BTC price drops by ≥20%,  it triggers the call condition and the smart contract executes the airdrop rewards to BLOWUP holders.\n' +
        '            <br /><br />\n' +
        '            We are dedicated to offering the best financial protection for crypto traders by using the power of blockchain and smart contracts. The goal of BLOWUP is to create a decentralized hedge fund to insure crypto traders and minimize losses.',
    content3_2: 'Read whitepaper',
    content4_1: 'Total Supply & Liquidity Pool',
    content4_2: 'The total supply of BLOWUP(BUP) is 1 trillion\n' +
        '            <br /><br />\n' +
        '            We added 500 billion BUP and 360 USDT to the liquidity pool as trading pair\n' +
        '            <br /><br />\n' +
        '            Initial LP Token will be destroyed after being deposited into the black hole address.',
    content4_3: 'Liquidity Pool Address',
    content4_4: 'Airdrop Mechanism',
    content4_5: 'Users who hold BUP can get token airdrops based on the proportion of their holdings to the total supply if the price of BTC dropped by more than or equal to 20% on the previous day (according to the daily candlestick chart of Coinbase).\n' +
        '            <br /><br />\n' +
        '            Let\'s say you hold 0.1% of the total supply and the airdrop amount is 50 trillion, you will get 5 billion BUP token as reimbursement if the BTC price drops ≥ 20%. The amount of airdrops each time is 5% of the remaining total. For example, the number of airdrops for the first time is 25 billion. The second airdrop will be 23.75 Billion. And so on.',
    content5_1: 'The safe place to buy BLOWUP is on PancakeSwap, if you are DEX masters!',
    content5_2: 'Buy on pancakeSwap',
    content5_3: 'Follow the Step by Step Guide below to get BLOWUP , if you are not familiar with decentralized exchange.',
    content5_4: 'Install and Creat a Trust Wallet',
    content5_5: 'Download <a target="_blank" href="https://trustwallet.com/">Trust Wallet</a> and create a wallet. Complete the basic setup of a multi-coin wallet. Remember to save your backup phrases!',
    content5_6: 'Add BLOWUP to your Wallet',
    content5_7: 'Tap the icon in the top-right and search for "BLOWUP". If it\'s not there, tap "Add Custom Token"..\n' +
        '                        <br /><br />\n' +
        '                        At the top, tap "Ethereum" next to Network, and change it to "Smart Chain".\n' +
        '                        <br /><br />\n' +
        '                        Copy the contract address and the info below\n' +
        '                        <br /><br />',
    content5_8: 'Contract address:',
    content5_9: 'Copy',
    content5_10: 'Name:',
    content5_11: 'BLOW UP COIN',
    content5_12: 'Symbol:',
    content5_13: 'Decimals:',
    content5_14: 'Deposit BNB to your wallet',
    content5_15: 'Select Smart Chain in your trust wallet and press receive to find your address.\n' +
        '                        <br /><br />\n' +
        '                        Deposit BNB (BEP20) into your wallet.',
    content5_16: 'Swap  BNB For BLOWUP on PancakeSwap',
    content5_17: 'Once you have BNB on your Trust Wallet, Go to DApps (or "Browser" for iPhones) at the bottom of the main screen.\n' +
        '                        <br /><br />\n' +
        '                        For iPhone users, open Safari Browser and then type in the URL: trust://browser_enable to enable DApp Browser.\n' +
        '                        <br /><br />\n' +
        '                        On DApp Brower, search PancakeSwap and Connect Trust Wallet\n' +
        '                        On the swap tap, “search name or paste address”, paste the token address:\n' +
        '                        <br /><br />',
    content5_18: 'Use BNB to swap BLOWUP token, click swap and confirm.',
    content6_1: 'What Blowup will be<br />up to in 2021',
    content6_2: '20 May, 2021',
    content6_3: 'Deployment of contract on testnet Deployment of contract on BSC Creation of PancakeSwap Liquidity Pair LP token & 50% of supply deposited into the black hole address Website Creation',
    content6_4: 'Q3, 2021',
    content6_5: 'Develop a smart contract reads the BTC/USD 24h performance from the oracle.',
    content6_6: 'Q4, 2021',
    content6_7: 'Develop a hedging smart contract for the crypto market. User stakesBLOWUP as a hedge fund to offset the crypto market fluctuation.',
    content6_8: 'Q1, 2022',
    content6_9: 'Work with major DeFi service providers and crypto wallets to provide diversify financial products to our users.\n' +
        '\n' +
        'Build a price prediction game rides on BTC/USD 24h performance, earn by predicting the Bitcoin market with BLOWUP coin.  ',
    footer_1: 'BLOWUP - a decentraized hedge fund for crypto traders<br />\n' +
        '                Let\'s make the crypto trading community helping one another',
    footer_2: 'join our community to get latest updates from us',
    footer_3: 'Resources',
    footer_4: 'Whitepaper'
}
