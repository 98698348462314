<template>
  <!-- 消息提示 -->
  <div :class="cpd_rooClass" :style="style" @webkitTransitionStart="transitionstart"
       @transitionstart="transitionstart"
       @webkitTransitionEnd="transitionend"
       @transitionend="transitionend"
       class="art-message">
    <div v-text="cpd_message"
         class="art-message-content">
    </div>
  </div>
</template>
<script>
// import g from '../../lib/g'

export const typeIcoinTextMap = {
  success: 'check_circle',
  error: 'cancel',
  info: 'info',
  warning: 'error'
}

const directionSideMap = {
  top: 'height',
  bottom: 'height',
  right: 'width',
  left: 'width'
}

const zIndex = 10000
const instances = []

export default {
  name: 'ArtMessage',
  data () {
    return {
      rootClass: '',
      style: {
        'z-index': '',
        top: '',
        right: '',
        bottom: '',
        left: ''
      },
      direction: 'top', // top right bottom left
      state: 'ready', // fadein fadeout
      type: 'info', // success error info warning
      message: '', // 内容文本或html
      onClose: null, // 结束触发事件
      duration: 1500, // 持续时间
      addDurationPerWord: 70, // 每个字附加持续时长
      margin: 40, // 定位方向上距离视口的边距
      multipleBetweenMargin: 20 // 多个组件同时显示时相互的边距
    }
  },
  computed: {
    cpd_iconText () {
      return typeIcoinTextMap[this.type]
    },
    cpd_rooClass () {
      let classes = [this.type, this.direction]
      this.state && classes.push(this.state)
      return classes.join(' ')
    },
    cpd_side () {
      return directionSideMap[this.direction]
    },
    cpd_message () {
      if (typeof this.message !== 'string') {
        console.warn('tips 提示内容不是文本字符类型', this.message)
        return String(this.message)
      }
      return this.message
    },
    cpd_delay () {
      let delay = this.duration + this.message.length * this.addDurationPerWord
      return delay
    }
  },
  mounted () {
    this.init()
  },
  beforeDestroy () {
    if (this.onClose) {
      this.onClose()
    }
    this.$el.parentNode.removeChild(this.$el)
  },
  methods: {
    init () {
      this.$nextTick(() => {
        this.initPosition()
        this.$nextTick(() => setTimeout(this.fadein, 50))
      })
    },
    /**
     * 初始设置定位
     */
    initPosition () {
      let rect = this.$el.getBoundingClientRect()
      let margin = this[this.direction] = rect[this.cpd_side]
      this.style[this.direction] = -margin + 'px'
      this.style.opacity = '0'
    },
    /**
     * 开始渐现
     */
    fadein () {
      let totalMargin = instances.reduce((result, c, i) => {
        // 用具有相同定位方向实例的偏移量相加
        if (this.direction === c.direction) {
          let rect = c.$el.getBoundingClientRect()
          return result + rect[this.cpd_side] + (i > 0 ? c.multipleBetweenMargin : c.margin)
        }
      }, 0)
      this[this.direction] = totalMargin += (instances.length ? this.multipleBetweenMargin : this.margin)
      this.style[this.direction] = totalMargin + 'px'
      this.style['z-index'] = instances.push(this) + zIndex + ''
      this.style.opacity = '1'
      this.state = 'fadein'
    },
    /**
     * 开始渐隐
     */
    fadeout () {
      this.state = 'fadeout'
      this.initPosition()
      instances.some((c, i, instances) => {
        if (c === this) {
          let rect = this.$el.getBoundingClientRect()
          let margin = rect[this.cpd_side]
          margin += (i > 0 ? c.multipleBetweenMargin : c.margin)
          // 获取排在后面的组件
          instances.slice(i + 1).forEach((c, j) => {
            // 具有相同定位方向实例的，且不为渐隐状态
            if (this.direction === c.direction && c.state !== 'fadeout') {
              let reMargin = i > 0 || j > 0 ? (c[c.direction] - margin + c.multipleBetweenMargin) : c.margin
              c.style[c.direction] = (c[c.direction] = reMargin) + 'px'
            }
          })
          this.instancesRemove() // 先从实例数组中将当前实例移除
          return true
        }
      })
    },
    /**
     * 从存储的实例数组中删移除
     */
    instancesRemove () {
      instances.some((c, i) => {
        if (c === this) {
          instances.splice(i, 1)
          return true
        }
      })
    },
    transitionstart () {
      switch (this.state) {
        case 'fadein':
          break
        case 'fadeout':
          break
      }
    },
    /**
     * 渐现渐隐动画结束事件
     */
    transitionend ({target, currentTarget}) {
      if (target !== currentTarget) {
        return
      }
      // console.log('transitionend this.state', this.duration)
      switch (this.state) {
        case 'fadein':
          this.state = 'pause'
          if (this.duration < 0) {
            break
          }
          setTimeout(this.fadeout, this.cpd_delay)
          break
        case 'fadeout':
          this.$destroy()
          break
      }
    }
  }
}
</script>

<style lang="less">
.art-message {
  display: flex;
  overflow: hidden;
  position: fixed;
  z-index: 9999;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #ebeef5;
  background-color: #edf2fc;
  color: #000000;
  min-width: 10%;
  max-width: 90%;
  font-size: 18px;
  line-height: 18px;
  transition: opacity .3s, top .4s, right .4s, bottom .4s, left .4s, transform .4s;
  &.ready {
    transition: none;
  }
  &.fadein, &.fadeout, &.pause {
  }
  &.success {
    border-color: #e1f3d8 !important;
    background-color: #f0f9eb !important;
    color: #67c23a !important;
    & > .v-icon {
      color: #67c23a !important;
    }
  }
  &.error {
    border-color: #fde2e2 !important;
    background-color: #fef0f0 !important;
    color: #f56c6c !important;
    & > .v-icon {
      color: #f56c6c !important;
    }
  }
  &.info {
    border: 1px solid #ebeef5 !important;
    background-color: #edf2fc !important;
    color: #000000 !important;
    & > .v-icon {
      color: #000000 !important;
    }
  }
  &.warning {
    border-color: #faecd8 !important;
    background-color: #fdf6ec !important;
    color: #e6a23c !important;
    & > .v-icon {
      color: #e6a23c !important;
    }
  }
  &.top {
    left: 50%;
    transform: translate(-50%, 0);
    top: -9999px;
  }
  &.bottom {
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -9999px;
  }
  &.right {
    top: 50%;
    transform: translate(0, -50%);
    right: -9999px;
  }
  &.left {
    top: 50%;
    transform: translate(0, -50%);
    left: -9999px;
  }
  & > .v-icon {
    padding: 0 6px;
    font-size: 18px;
  }
  & > .art-message-content {
    flex-grow: 1;
    padding: 0 6px;
  }
}

</style>
