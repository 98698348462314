<script>
export default {
  name: 'app',
  mounted () {

  },
  render (h) {
    if (this.$mobile) {
      return h('MLayout')
    } else {
      return h('PCLayout')
    }
  }
}
</script>
