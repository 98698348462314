import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './config/lang'
import './plugins/ajax'
import './plugins/message'
import WEB_API from './config/api'

Vue.component('MLayout', () => import('./layout/mobile'))
Vue.component('PCLayout', () => import('./layout/pc'))

Vue.config.productionTip = false

Vue.prototype.$api = WEB_API

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
