export default {
    local: 'English',
    home: '首页',
    paper: '白皮书',
    download: '下载',
    content1: '<p>PI Dawn-network</p>构建基于PI Network的<br/>多元应用场景',
    downtitle: 'PDN Wallet下载',
    ios: 'IOS下载',
    android: 'Android下载',
    content2_1: '去中心化金融',
    content2_2: '率先打造基于PI Network的Defi应用；完全去中心化的委托系统，自动撮合交易、借贷等需求。',
    content2_3: '去中心化治理',
    content2_4: '将治理分散给所有代币持有者，通过DAO治理将系统的控制和方向转移给全球代币持有者。',
    content2_5: '多元化应用场景',
    content2_6: '在PI Network巨大的网络生态系统中，创造更丰富更便捷的区块链应用，为PI币及PDN进行价值赋能；如存储Pi赚取PDN。',
    content3_1: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PDN是您可以在手机上开采的数字货币之一；<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;我们希望世界上大量的人可以获得PDN，从而拥抱加密货币革命。',
    content3_2: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;与PI相同，PDN也是免费的。您所需要的只是下载移动应用，然后开始挖掘。您不用担心电气浪费，也不会消耗电池电量。<br/><br/> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;数字货币是面向新世界的车票。未来有价值的数字资产，不只是PDN。但会有PDN。',
    content4_1: '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;PI Network推出市场两年以来，已有千万共识矿工，PI Network的创新技术是数字货币发展历程一次质的飞跃，是区块链进化的新物种；新物种进化的过程，需要更多的陪伴与耐心，PI使加密挖矿变得更容易，PDN将使PI更有价值。作为PI的分叉币，PDN将在应用场景上不断丰富和完善，为PI Network赋予更多的价值，也让更多的旷工能够拥抱希望。',
    content4_2: '黎明将至',
    reg: '注册',
    newreg: '注册',
    clickdownload: '点击下载',
    guojia: '国家',
    mail: '邮箱',
    code: '邀请码',
    scode: '验证码',
    password: '密码',
    xuanze: '请选择',
    msg19: '注册成功',
    msg12: '请输入邮箱地址',
    shuru: '请输入',
    fasongyanzhengma: '发送验证码',
    chongxinfasong: '重新发送'
}
